import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Alert from "@mui/material/Alert";
import { CoverLetterBlockComponent } from "../../components/CoverLetter/Block/CoverLetterBlockComponent";
import Footer from "../../components/CoverLetter/Footer";
import CoverLetterStatus from "../../enums/CoverLetterStatus";
import { fetchUserCoverLetterGenerationQuota } from "../../hook/CoverLetter/FetchUserCoverLetterGenerationQuota";
import { fetchCoverLetterBlockData } from "../../hook/CoverLetterBlock/FetchCoverLetterBlockData";
import { fetchCreateCoverLetterBlock } from "../../hook/CoverLetterBlock/FetchCreateCoverLetterBlock";
import { fetchDeleteCoverLetterBlock } from "../../hook/CoverLetterBlock/FetchDeleteCoverLetterBlock";
import { fetchSaveAll } from "../../hook/CoverLetter/FetchSaveAll";
import { CoverLetterAPIPrefix } from "../../hook/CoverLetter/const";
import SelectResume from "../../components/CoverLetter/SelectResume";
import HeaderTextFieldContainer from "../../components/CoverLetter/HeaderTextField";
import HeaderTitleTextField from "../../components/CoverLetter/HeaderTitleTextField";

function CoverLetterPageComponent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [header, setHeader] = useState({});
  const [textFields, setTextFields] = useState([]);
  const [selectResume, setSelectResume] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [generationQuota, setGenerationQuota] = useState(0);

  const handleAddTextField = async (coverLetterId) => {
    var response = await fetchCreateCoverLetterBlock(coverLetterId);
    if (response.status === "SUCCESS")
      setTextFields((prevTextFields) => [...prevTextFields, response.data]);
    else {
      enqueueSnackbar(`최대 ${response.maxCount}개까지 추가할 수 있습니다.`, {
        autoHideDuration: 5000,
        content: (key, message) => (
          <Alert
            onClose={() => closeSnackbar(key)}
            severity="error"
            variant="filled"
            sx={{ width: "100%", backgroundColor: "#1976d2", zIndex: 2000 }}
          >
            {message}
          </Alert>
        ),
      });
    }
  };

  const handleRemoveTextField = async (coverLetterId) => {
    if (textFields.length > 1) {
      var count = textFields.length;
      await fetchDeleteCoverLetterBlock(
        coverLetterId,
        textFields.slice(count - 1, count)[0].id
      );
      setTextFields((prevTextFields) =>
        prevTextFields.slice(0, prevTextFields.length - 1)
      );
    }
  };
  const handleHeaderStateChange = (headerId, updateValue) => {
    setHeader((states) => ({ ...states, [headerId]: updateValue }));
  };

  const handleStateChange = (id, newState) => {
    setTextFields((prevStates) => {
      return prevStates.map((state) => {
        if (state.id === id) {
          return newState;
        }
        return state;
      });
    });
  };

  const handleTempSave = async () => {
    await fetchSaveAll(
      id,
      header,
      Object.keys(CoverLetterStatus).find(
        (key) => CoverLetterStatus[key] === CoverLetterStatus.IN_PROGRESS
      ),
      textFields
    );
  };
  const handleSaveAll = async () => {
    await fetchSaveAll(
      id,
      header,
      Object.keys(CoverLetterStatus).find(
        (key) => CoverLetterStatus[key] === CoverLetterStatus.SUCCESS
      ),
      textFields
    );
    navigate(`${CoverLetterAPIPrefix}`);
  };

  async function initPage(coverLetterId) {
    await fetchCoverLetterBlockData(coverLetterId, setTextFields, setHeader);
  }

  useEffect(() => {
    initPage(id);
    fetchUserCoverLetterGenerationQuota(setGenerationQuota);
  }, []);

  return (
    <div
      style={{
        display: "block",
        minHeight: "calc(100vh - 18vh)",
      }}
    >
      <div style={styles.contentContainer}>
        <div style={styles.headerContainer}>
          <HeaderTitleTextField
            value={header.title}
            onUpdate={(newState, updateValue) => {
              handleHeaderStateChange(newState, updateValue);
            }}
          />
          <HeaderTextFieldContainer
            header={header}
            onUpdate={(newState, updateValue) => {
              handleHeaderStateChange(newState, updateValue);
            }}
          />
        </div>
        <div style={styles.textFieldsContainer}>
          <div style={styles.Container}>
            <div>
              <IconButton
                onClick={() => {
                  handleAddTextField(id);
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleRemoveTextField(id);
                }}
              >
                <RemoveIcon />
              </IconButton>
            </div>
            <SelectResume
              coverLetterId={id}
              resumeName={header.resume}
              setSelectResume={setSelectResume}
              setHeader={setHeader}
              generationQuota={generationQuota}
            />
          </div>
          {textFields.map((value, index) => (
            <CoverLetterBlockComponent
              key={index + 1}
              data={value}
              onUpdate={(newState) => handleStateChange(value.id, newState)}
              handleTempSave={handleTempSave}
              selectResume={selectResume}
              generationQuota={generationQuota}
              setGenerationQuota={setGenerationQuota}
            />
          ))}
        </div>
      </div>
      <Footer handleTempSave={handleTempSave} handleSaveAll={handleSaveAll} />
    </div>
  );
}

const styles = {
  contentContainer: {
    width: "100%",
    minHeight: "calc(100vh - 18vh - 3vh)",
    display: "flex",
    marginTop: "3vh",
    marginBottom: "3vh",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    borderBottom: "1px solid black",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: "30px",
  },
  Container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingTop: "15px",
  },
  textFieldsContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "80%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};

export default function CoverLetterPage() {
  return (
    <SnackbarProvider maxSnack={3}>
      <CoverLetterPageComponent />
    </SnackbarProvider>
  );
}
