import axiosClient from "../AxiosClient";

export async function fetchResumeUpload(file) {
  const body = new FormData();
  body.append("file", file);

  await axiosClient.post(`/file/upload`, body).catch(function (error) {
    console.log(error);
  });
}
