import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteDialog from "../Dialog/Dialog";

export default function DeleteResumeAlertDlalog({
  type,
  onClick,
  value,
  fileId,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton
        size="large"
        style={{
          marginLeft: "24px",
          marginRight: "24px",
          height: "50px",
          width: "50px",
        }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <DeleteForeverIcon />
      </IconButton>
      <DeleteDialog
        open={open}
        type={type}
        value={value}
        handleClose={handleClose}
        onDelete={onClick}
        fileId={fileId}
      />
    </React.Fragment>
  );
}
