import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Button } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import "./Navbar.css";
import {
  TermsPath,
  PrivacyPath,
  PrivacyAgreement,
} from "../../constant/terms/Path";

const Navbar = () => {
  const koList = ["이용약관", "개인정보처리방침", "개인정보처리동의서"];
  const pathList = [TermsPath, PrivacyPath, PrivacyAgreement];
  const [activeButton, setActiveButton] = useState(null);
  const navigate = useNavigate();

  const handleButtonClick = (idx) => {
    setActiveButton(idx);
    navigate(pathList[idx]);
  };

  useEffect(() => {
    pathList.map((path, idx) => {
      if (window.location.pathname === path) {
        setActiveButton(idx);
      }
    });
  }, []);

  return (
    <AppBar
      position="static"
      style={{
        margin: "0 auto",
        boxShadow: "none",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        borderBottom: `2px solid ${blueGrey[100]}`,
      }}
    >
      <Toolbar className="toolbar">
        {koList.map((label, idx) => (
          <Button
            key={label}
            className={`nav-button ${activeButton === idx ? "active" : ""}`}
            onClick={() => handleButtonClick(idx)}
            sx={{
              color: "black",
              borderColor: "gray",
              fontSize: "16px",
              width: "100%",
              height: "65px",
            }}
          >
            {label}
          </Button>
        ))}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
