import { useKeycloak } from "@react-keycloak/web";
import { Login } from "../components/Login";

const PrivateRoute = ({ element: Element }) => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return keycloak?.authenticated ? Element : <Login />;
};

export default PrivateRoute;
