import axiosClient from "../AxiosClient";

export async function fetchUserCoverLetterGenerationQuota(setGenerationQuota) {
  await axiosClient
    .get("/account/cover-letter/quota")
    .then(function (response) {
      setGenerationQuota(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}
