import TextField from "@mui/material/TextField";

const RelatedExperienceTextField = ({
  isTextFieldVisible,
  handleInputChange,
  related_experience,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {isTextFieldVisible && (
        <TextField
          type="text"
          name="related_experience"
          onChange={handleInputChange}
          value={related_experience || undefined}
          multiline
          placeholder="강조하고 싶은 내용 또는 답변 생성에 참조할 경험을 입력하세요."
          style={{
            display: "flex",
            flex: 1,
          }}
        />
      )}
    </div>
  );
};

export default RelatedExperienceTextField;
