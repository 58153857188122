import Button from "@mui/material/Button";

export function NewResumeBtn({ handleButtonClick }) {
  return (
    <Button
      variant="outlined"
      style={{
        width: "150px",
        height: "50px",
        borderRadius: "10px",
      }}
      onClick={handleButtonClick}
    >
      업로드
    </Button>
  );
}
