import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchKakaopayCancelOrFail } from "../../../hook/payment/kakao/FetchKakaoPayCancelOrFail";

function CancelPage() {
  const navigate = useNavigate();

  useEffect(() => {
    async function approve() {
      await fetchKakaopayCancelOrFail("CANCEL");
      localStorage.removeItem("tid");
      localStorage.removeItem("product_id");
      localStorage.removeItem("total_amount");

      navigate("/price");
    }
    approve();
  }, [navigate]);

  return;
}

export default CancelPage;
