import axiosClient from "../AxiosClient";
import { CoverLetterBlockAPIPrefix } from "../CoverLetter/const";

export async function fetchCoverLetterBlockData(
  coverLetterId,
  setTextFields,
  setHeader
) {
  await axiosClient
    .get(`${CoverLetterBlockAPIPrefix}/${coverLetterId}`)
    .then(function (response) {
      var data = response.data;
      setHeader({
        title: data.header.title,
        status: data.header.status,
        resume: data.header.resume,
        job: data.header.job,
        task_description: data.header.task_description,
        requirements: data.header.requirements,
        preferential: data.header.preferential,
      });
      setTextFields([]);
      data.body.forEach((value) => {
        setTextFields((prevTextFields) => [...prevTextFields, value]);
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}
