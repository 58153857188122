import axiosClient from "../AxiosClient";

export async function fetchResumeMaxCount(setMaxFileNumber) {
  await axiosClient
    .get(`/file/count`)
    .then(function (response) {
      setMaxFileNumber(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}
