import axiosClient from "../AxiosClient";

export async function fetchProductList(setProductMenuList) {
  await axiosClient
    .get("/payment/product/list")
    .then(function (response) {
      setProductMenuList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}
