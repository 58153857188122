// Global Navigation Bar
// Menu: Resume, CoverLetter, MyPage
import { blueGrey } from "@mui/material/colors";
import { Button } from "@mui/material";
import GNB from "./GNB";
import { useNavigate } from "react-router-dom";
import AccountMenu from "./AccountMenu";

const Header = () => {
  const navigate = useNavigate();
  return (
    <header
      style={{
        position: "sticky",
        top: 0,
        height: "calc(8vh - 1px)",
        borderBottom: `1px solid ${blueGrey[100]}`,
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        zIndex: 1000,
        backgroundColor: "white",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex" }}>
        <Button
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "20vw",
            fontSize: "28px",
            fontWeight: 700,
            // textTransform: "none",
          }}
          onClick={() => navigate("/")}
        >
          CV Guide
        </Button>
        <GNB />
      </div>
      <div
        style={{
          display: "flex",
          width: "20vw",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AccountMenu />
      </div>
    </header>
  );
};

export default Header;
