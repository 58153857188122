import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import { backGround } from "../../colors/BackGround";

function CoverLetterMainPage() {
  return (
    <div style={styles.page}>
      <Header />
      <Outlet />
    </div>
  );
}

const styles = {
  page: {
    height: "100vh",
    width: "100vw",
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: backGround,
  },
};

export default CoverLetterMainPage;
