import React, { useState, useEffect } from "react";
import CoverLetterCard from "../../components/CoverLetter/CoverLetterCard";
import NewCoverLetterIntegrationNotistack from "../../components/CoverLetter/NewCoverLetterCard";
import { fetchCoverLetterList } from "../../hook/CoverLetter/FetchCoverLetterList";
import { fetchDeleteCoverLetter } from "../../hook/CoverLetter/FetchDeleteCoverLetter";
import { fetchCoverLetterCount } from "../../hook/CoverLetter/FetchCoverLetterCount";

function CoverLetterListPage() {
  const [coverLetter, setCoverLetter] = useState([]);
  const [maxCount, setMaxCount] = useState(0);

  async function handleCreateCoverLetterBtn() {
    fetchCoverLetterList(setCoverLetter);
  }

  async function handleDeleteBtnOnClick(coverLetterId) {
    await fetchDeleteCoverLetter(coverLetterId);
    fetchCoverLetterList(setCoverLetter);
  }

  useEffect(() => {
    fetchCoverLetterCount(setMaxCount);
    fetchCoverLetterList(setCoverLetter);
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.coverLetterListViewContainer}>
        <NewCoverLetterIntegrationNotistack
          onClick={handleCreateCoverLetterBtn}
          nowCount={coverLetter.length}
          maxCount={maxCount}
        />
        {coverLetter.map((value, index) => (
          <CoverLetterCard
            key={index}
            cardData={value}
            handleDeleteBtnOnClick={handleDeleteBtnOnClick}
          />
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    width: "100vw",
    alignItems: "center",
    flexDirection: "column",
  },
  coverLetterListViewContainer: {
    marginTop: "5vh",
    marginBottom: "5vh",
    display: "flex",
    width: "calc((18vw * 4) + 60px)",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
  },
};

export default CoverLetterListPage;
