import { Button } from "@mui/material";
import { fetchKakaopayReady } from "../../hook/payment/kakao/FetchKakaoPayReady";

export default function ProductMenu({ productId, productName, price }) {
  return (
    <div
      style={{
        width: "60vw",
        maxWidth: "800px",
        height: "10vh",
        maxHeight: "200px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        backgroundColor: "white",
        alignItems: "center",
        padding: "12px 36px",
      }}
    >
      <div>
        <h3>{productName}</h3>
      </div>
      <Button
        size="large"
        variant={"contained"}
        style={{ width: "110px", textAlign: "end" }}
        onClick={async () => {
          window.localStorage.setItem("product_id", productId);
          window.localStorage.setItem("total_amount", price);
          const response = await fetchKakaopayReady();
          window.localStorage.setItem("tid", response.data.tid);
          window.location.replace(response.data.next_redirect_pc_url);
        }}
      >
        ￦ {price.toLocaleString()}
      </Button>
    </div>
  );
}
