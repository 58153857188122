import axiosClient from "../../AxiosClient";

export async function fetchKakaopayCancelOrFail(status) {
  const data = {
    tid: window.localStorage.getItem("tid"),
    product_id: window.localStorage.getItem("product_id"),
    total_amount: window.localStorage.getItem("total_amount"),
    status: status,
  };
  await axiosClient.post(`/payment/kakao-pay/v1/error`, data);
}
