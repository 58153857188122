import axiosClient from "../AxiosClient";
import { CoverLetterAPIPrefix } from "./const";

export async function fetchSaveResumeName(coverLetterId, fileId) {
  var result = {};

  const body = { cover_letter_id: coverLetterId, file_id: fileId };
  await axiosClient
    .patch(`${CoverLetterAPIPrefix}/resume/save`, body)
    .then(function (response) {
      result = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });

  return result;
}
