import axios from "axios";
import keycloak from "../provider/keycloak";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_PROXY_URL, // Replace with your API base URL
});

// Request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    // // Modify the request config here (e.g., add headers, authentication tokens)
    // const accessToken = JSON.parse(localStorage.getItem("token"));

    // // ** If token is present add it to request's Authorization Header
    // if (accessToken) {
    //   if (config.headers) config.headers.token = accessToken;
    // }
    return config;
  },
  (error) => {
    // Handle request errors here

    return Promise.reject(error);
  }
);

// Response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    // Modify the response data here (e.g., parse, transform)
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // window.location.reload();
      keycloak.login();
      // console.log("??");
    }
    if (error.response.status === 403) {
      // redirect
      // window.location.replace("/");
      keycloak.login();
      // console.log("??");
    }

    // Handle response errors here

    return Promise.reject(error);
  }
);

export default axiosClient;
