import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import keycloak from "./provider/keycloak";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import axiosClient from "./hook/AxiosClient";

const keycloakInitConfig = {
  onLoad: "check-sso",
  checkLoginIframe: true,
  pkceMethod: "S256",
};

const onKeycloakEvent = (event, error) => {
  if (event === "onAuthSuccess") {
    axiosClient.defaults.headers.Authorization = keycloak.token;
  }
};

const onKeycloakTokens = (tokens) => {
  axiosClient.defaults.headers.Authorization = tokens.token;
};

const KeycloakWrapper = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    async function checkUserExists() {
      // Check if the user exists
      await axiosClient.post("/account", null).catch((error) => {
        console.error("Error checking user account:", error);
      });
    }

    if (initialized && keycloak.authenticated) {
      axiosClient.defaults.headers.Authorization = keycloak.token;

      keycloak.onTokenExpired = () => {
        console.log("Token expired");
      };
      checkUserExists();
    }
  }, [initialized, keycloak]);

  return children;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={keycloakInitConfig}
    onEvent={onKeycloakEvent}
    onTokens={onKeycloakTokens}
  >
    <KeycloakWrapper>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </KeycloakWrapper>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
