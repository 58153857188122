import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MyPagePath } from "../../../constant/MenuPath";
import { fetchKakaopayApprove } from "../../../hook/payment/kakao/FetchKakaoPayApprove";

function SuccessPage() {
  const location = useLocation();
  //search르 통해서 ?뒤에 붙은 값을 가져온다
  const url = location.search;
  //=뒤에 붙은 pg_token값을 가져온다.
  const pgToken = url.split("=")[1];

  const navigate = useNavigate();
  useEffect(() => {
    async function approve() {
      await fetchKakaopayApprove(pgToken);
      localStorage.removeItem("tid");
      localStorage.removeItem("product_id");
      localStorage.removeItem("total_amount");

      navigate(MyPagePath + "/billing");
    }
    approve();
  }, [navigate, pgToken]);

  return;
}

export default SuccessPage;
