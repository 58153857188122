import React from "react";
import TextField from "@mui/material/TextField";

function HeaderTextField({ id, title, value, onUpdate }) {
  const variant = "outlined";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "20px",
        width: "100%",
        gap: "10px",
      }}
    >
      <div
        style={{
          width: "100px",
          display: "flex",
          fontSize: "18px",
        }}
      >
        {title}
      </div>
      <TextField
        name={id}
        type="text"
        variant={variant}
        fullWidth={true}
        value={value || undefined}
        multiline
        onChange={(event) => onUpdate(event.target.name, event.target.value)}
      />
    </div>
  );
}

export default function HeaderTextFieldContainer({ header, onUpdate }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginBottom: "20px",
      }}
    >
      <HeaderTextField
        id={"job"}
        title={"직무"}
        value={header.job}
        onUpdate={onUpdate}
      />
      <div style={{ display: "flex", width: "100%", gap: "30px" }}>
        <HeaderTextField
          id={"task_description"}
          title={"수행업무"}
          value={header.task_description}
          onUpdate={onUpdate}
        />
        <HeaderTextField
          id={"requirements"}
          title={"자격요건"}
          value={header.requirements}
          onUpdate={onUpdate}
        />
        <HeaderTextField
          id={"preferential"}
          title={"우대사항"}
          value={header.preferential}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  );
}
