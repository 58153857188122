import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ResumePage from "./pages/ResumePage";
import CoverLetterMainPage from "./pages/CoverLetter/CoverLetterMainPage";
import CoverLetterListPage from "./pages/CoverLetter/CoverLetterListPage";
import CoverLetterPage from "./pages/CoverLetter/CoverLetterPage";
import MyPage from "./pages/MyPage/MyPage";
import ProfilePage from "./pages/MyPage/ProfilePage";
import BillingPage from "./pages/MyPage/BillingPage";
import PaymentPage from "./pages/Payment/PaymentPage";
import {
  ResumePath,
  CoverLetterPath,
  MyPagePath,
  PricePath,
  PaymentPath,
} from "./constant/MenuPath";
import PriceMainPage from "./pages/Price/PriceMainPage";
import CancelPage from "./pages/Payment/Kakao/cancel";
import FailPage from "./pages/Payment/Kakao/fail";
import SuccessPage from "./pages/Payment/Kakao/success";
import PrivateRoute from "./router/PrivateRouter";
import NotFound from "./components/NotFound";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Terms/Privacy";
import Agreement from "./pages/Terms/Agreement";
import TermsHome from "./pages/Terms/TermsHome";

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/terms" element={<TermsHome />}>
            <Route exact path="" element={<Terms />} />
            <Route exact path="privacy" element={<Privacy />} />
            <Route exact path="privacy-agreement" element={<Agreement />} />
          </Route>
          <Route
            exact
            path={ResumePath}
            element={<PrivateRoute element={<ResumePage />} />}
          />
          <Route
            exact
            path={CoverLetterPath}
            element={<PrivateRoute element={<CoverLetterMainPage />} />}
          >
            <Route exact path="" element={<CoverLetterListPage />} />
            <Route exact path=":id" element={<CoverLetterPage />} />
          </Route>
          <Route
            exact
            path={PricePath}
            element={<PrivateRoute element={<PriceMainPage />} />}
          />
          <Route
            exact
            path={MyPagePath}
            element={<PrivateRoute element={<MyPage />} />}
          >
            <Route exact path="" element={<ProfilePage />} />
            <Route exact path="billing" element={<BillingPage />} />
          </Route>
          <Route
            exact
            path={PaymentPath}
            element={<PrivateRoute element={<PaymentPage />} />}
          >
            <Route exact path="success" element={<SuccessPage />} />
            <Route exact path="fail" element={<FailPage />} />
            <Route exact path="cancel" element={<CancelPage />} />
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
