import axiosClient from "../AxiosClient";
import { CoverLetterAPIPrefix } from "./const";

export async function fetchCreateCoverLetter() {
  const response = await axiosClient
    .post(`${CoverLetterAPIPrefix}/create`, null)
    .catch(function (error) {
      console.log(error);
    });
  return response.data;
}
