import { blueGrey } from "@mui/material/colors";

export function InfoField({ title, value, disabled = false }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}
      >
        {title}
      </div>
      <input
        value={value || ""}
        disabled={disabled}
        style={{
          padding: "6px 12px",
          fontSize: "14px",
          lineHeight: "20px",
          borderRadius: "6px",
          border: `1px solid ${blueGrey[200]}`,
          marginBottom: "25px",
        }}
      />
    </div>
  );
}
