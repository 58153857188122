import axiosClient from "../../AxiosClient";

export async function fetchKakaopayApprove(pg_token) {
  const data = {
    pg_token: pg_token,
    tid: window.localStorage.getItem("tid"),
    product_id: window.localStorage.getItem("product_id"),
    total_amount: window.localStorage.getItem("total_amount"),
  };
  const response = await axiosClient.post(
    `/payment/kakao-pay/v1/approve`,
    data
  );
  return response;
}
