import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

function AITextField(props) {
  const inputCount = props.text ? props.text.length : 0;
  const endAdornment = (
    <Button onClick={() => props.handleButtonClick()}>생성</Button>
  );

  const textFieldStyles = {
    "& .MuiInputBase-root": { minHeight: 70 },
    marginBottom: "8px",
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        paddingBottom: "24px",
      }}
    >
      {
        <TextField
          type="text"
          name={props.aiTextFieldName}
          onChange={(e) => props.onChange(e)}
          value={props.text || undefined}
          placeholder="버튼을 누르면 예시 자기소개서가 생성됩니다."
          multiline
          disabled={true}
          InputProps={{
            endAdornment: endAdornment,
          }}
          sx={textFieldStyles}
        />
      }
      <div style={{ fontSize: 14, textAlign: "end" }}>
        공백 포함 {inputCount} 자
      </div>
    </div>
  );
}
export default AITextField;
