import axiosClient from "../AxiosClient";

export async function fetchResumeList(setResumeList) {
  await axiosClient
    .get(`/file/resume/list`)
    .then(function (response) {
      setResumeList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}
