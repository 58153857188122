import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { fetchSaveQuestion } from "../../../hook/CoverLetter/FetchSaveQuestion";

const QuestionComponent = ({
  handleInputChange,
  coverLetterBlockData,
  isEmptyQuestion,
  setIsEmptyQuestion,
  tempSaveTrigger,
}) => {
  const [isError, setIsError] = useState(isEmptyQuestion);
  const [isEditing, setIsEditing] = useState(isError ? true : false);
  let textInput = React.useRef(null);

  function focusTextInput() {
    textInput.current.focus();
  }

  function handleTextFieldOnChange() {
    setIsError(false);
    setIsEmptyQuestion(false);
  }

  useEffect(() => {
    setIsError(isEmptyQuestion ? true : false);
    if (isEmptyQuestion) {
      setIsEditing(isEmptyQuestion ? true : false);
    }
  }, [isEmptyQuestion]);

  useEffect(() => {
    if (isEditing) {
      focusTextInput();
    }
  }, [isEditing]);

  useEffect(() => {
    setIsEditing(false);
  }, [tempSaveTrigger]);

  return (
    <FormControl variant="outlined" style={{ minWidth: "150px", flex: 1 }}>
      <TextField
        inputRef={textInput}
        error={isError}
        placeholder="질문을 입력하세요."
        name="question"
        onChange={(event) => {
          handleInputChange(event);
          handleTextFieldOnChange();
        }}
        value={coverLetterBlockData.question || undefined}
        multiline
        disabled={!isEditing}
        InputProps={{
          endAdornment: (
            <Button
              onClick={async () => {
                if (isEditing) {
                  await fetchSaveQuestion(
                    coverLetterBlockData.id,
                    coverLetterBlockData.question
                  );
                }
                setIsEditing((prevState) => !prevState);
              }}
            >
              {isEditing ? "저장" : "편집"}{" "}
            </Button>
          ),
        }}
      />
    </FormControl>
  );
};

export default QuestionComponent;
