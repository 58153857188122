import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { blueGrey } from "@mui/material/colors";
import { ResumePath, CoverLetterPath, PricePath } from "../constant/MenuPath";

const Btn = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Button
      style={{
        display: "flex",
        width: "10vw",
        height: "60%",
        alignItems: "center",
        justifyContent: "center",
        color: blueGrey[800],
      }}
      onClick={() => navigate(data.path)}
    >
      {data.name}
    </Button>
  );
};

function GNB() {
  const menus = [
    { name: "이력서", path: ResumePath },
    { name: "자기소개서", path: CoverLetterPath },
    // { name: "결제", path: PricePath },
  ];

  return (
    <ButtonGroup
      variant="text"
      color="inherit"
      size="large"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        height: "100%",
        borderRadius: "0px",
      }}
    >
      {menus.map((menu, index) => {
        return <Btn key={index} data={menu} />;
      })}
    </ButtonGroup>
  );
}

export default GNB;
