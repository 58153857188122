import axiosClient from "../AxiosClient";

export async function fetchFileName(fileId, setViewResumeName) {
  await axiosClient
    .get(`/file/${fileId}/name`)
    .then(function (response) {
      setViewResumeName(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}
