import axiosClient from "../AxiosClient";
import { CoverLetterAPIPrefix } from "./const";

export async function fetchCoverLetterCount(setMaxCount) {
  await axiosClient
    .get(`${CoverLetterAPIPrefix}/count`)
    .then(function (response) {
      setMaxCount(response.data.maxCount);
    })
    .catch(function (error) {
      console.log(error);
    });
}
