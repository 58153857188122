import { useEffect, useState, useRef, Fragment } from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { ResumeBtn } from "./ResumeBtn";
import { NewResumeBtn } from "./NewResumeBtn";
import { blueGrey } from "@mui/material/colors";
import Alert from "@mui/material/Alert";
import { fetchResumeList } from "../../hook/Resume/FetchResumeList";
import { fetchResumeUpload } from "../../hook/Resume/FetchResumeUpload";
import { fetchResumeMaxCount } from "../../hook/Resume/FetchResumeMaxCount";

function ResumeListComponent() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [resumeList, setResumeList] = useState([]);
  const fileInput = useRef();
  const fileLimitMB = 50;
  const [maxFileNumber, setMaxFileNumber] = useState(10);

  const handleButtonClick = () => {
    if (resumeList.length >= maxFileNumber) {
      enqueueSnackbar(
        `이력서는 최대 ${maxFileNumber}개까지 업로드 할 수 있습니다!`,
        {
          autoHideDuration: 5000,
          content: (key, message) => (
            <Alert
              onClose={() => closeSnackbar(key)}
              severity="error"
              variant="filled"
              sx={{ width: "100%", backgroundColor: "#1976d2" }}
            >
              {message}
            </Alert>
          ),
        }
      );
      return;
    }
    fileInput.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size <= fileLimitMB * 1024 * 1024) {
        // 50MB 이하 파일만 허용
        await fetchResumeUpload(file);
        fetchResumeList(setResumeList);
        fileInput.current.value = "";
      } else {
        enqueueSnackbar(
          `${fileLimitMB}MB 이상의 이력서 파일은 업로드 할 수 없습니다.`,
          {
            autoHideDuration: 5000,
            content: (key, message) => (
              <Alert
                onClose={() => closeSnackbar(key)}
                severity="error"
                variant="filled"
                sx={{ width: "100%", backgroundColor: "#1976d2" }}
              >
                {message}
              </Alert>
            ),
          }
        );
      }
    }
  };

  useEffect(() => {
    fetchResumeMaxCount(setMaxFileNumber);
    fetchResumeList(setResumeList);
  }, []);

  return (
    <Fragment>
      <div
        style={{
          width: "50vw",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          padding: "24px 48px",
          gap: "12px",
        }}
      >
        <h3>
          내 이력서 ({resumeList.length} / {maxFileNumber})
        </h3>
        {resumeList.map((value, index) => {
          return (
            <ResumeBtn
              key={index}
              fileId={value.id}
              filename={value.filename}
              created={value.created}
              setResumeList={setResumeList}
            />
          );
        })}
        <NewResumeBtn handleButtonClick={handleButtonClick} />
        <input
          type="file"
          accept=".pdf"
          onChange={handleFileChange}
          style={{ display: "none" }}
          ref={fileInput}
        />
        <div style={{ fontSize: "14px", color: blueGrey[700] }}>
          한번에 {fileLimitMB}MB 이내의 PDF 파일을 업로드 할 수 있습니다.
        </div>
      </div>
    </Fragment>
  );
}

export function ResumeList() {
  return (
    <SnackbarProvider maxSnack={3}>
      <ResumeListComponent />
    </SnackbarProvider>
  );
}
