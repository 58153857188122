import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import { backGround } from "../../colors/BackGround";
import { Sidebar } from "../../components/MyPage/Sidebar";

function MyPage() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflowX: "hidden",
        overflowY: "auto",
        backgroundColor: backGround,
      }}
    >
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginTop: "3vh",
          marginBottom: "3vh",
          padding: "0px 15%",
          flexWrap: "wrap",
          gap: "25px",
        }}
      >
        {/* <Sidebar /> */}
        <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default MyPage;
