import { Outlet } from "react-router-dom";
import "../../App.css";
import Navbar from "./Navbar";

export default function TermsHome() {
  return (
    <div style={{ backgroundColor: "white" }}>
      <header className="App-header">
        <a className="logo" href={process.env.REACT_APP_HOME_URL}>
          CV Guide
        </a>
      </header>
      <Navbar />
      <div
        style={{
          width: "60%",
          maxWidth: "1024px",
          alignContent: "center",
          padding: "30px 20px",
          margin: "0 auto",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
}
