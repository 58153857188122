import React from "react";
import Button from "@mui/material/Button";
import { blueGrey } from "@mui/material/colors";

const Btn = ({ text, variant, handleOnClick }) => {
  return (
    <Button variant={variant} onClick={() => handleOnClick()}>
      {text}
    </Button>
  );
};

function Footer({ handleTempSave, handleSaveAll }) {
  return (
    <div
      style={{
        display: "flex",
        height: "calc(8vh - 1px)",
        maxHeight: "64px",
        flexDirection: "row",
        alignContent: "center",
        zIndex: 1000,
        backgroundColor: "white",
        position: "sticky",
        bottom: 0,
        borderTop: `1px solid ${blueGrey[100]}`,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "80%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <Btn
            text={"임시 저장"}
            variant={"outlined"}
            handleOnClick={handleTempSave}
          />
          <Btn
            text={"작성 완료"}
            variant={"contained"}
            handleOnClick={handleSaveAll}
          />
        </div>
      </div>
    </div>
  );
}

export default Footer;
