import axiosClient from "../AxiosClient";
import { CoverLetterBlockAPIPrefix } from "../CoverLetter/const";

export async function fetchCreateCoverLetterBlock(coverLetterId) {
  var result = {};

  await axiosClient
    .post(`${CoverLetterBlockAPIPrefix}/${coverLetterId}`, null)
    .then(function (response) {
      result = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });

  return result;
}
