import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { blueGrey } from "@mui/material/colors";
import { fetchPaymentHistory } from "../../hook/MyPage/FetchPaymentHistory";
import PaymentStatus from "../../enums/PaymentStatus";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: blueGrey[500],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export function Billing() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchPaymentHistory(setRows);
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }} size="large" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ minWidth: 150 }} align="center">
              결제일시
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: 150 }} align="center">
              이용권
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: 150 }} align="center">
              결제상태
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: 100 }} align="right">
              결제금액
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center">{row.createdAt}</TableCell>
              <TableCell align="center">{row.productName}</TableCell>
              <TableCell align="center">
                {PaymentStatus[row.paymentStatus]}
              </TableCell>
              <TableCell align="right">
                {row.paymentPrice.toLocaleString()} 원
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
