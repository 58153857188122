import React from "react";
import AITextField from "./AITextField";
import UserTextField from "./UserTextField";

const CoverLetterTextField = ({
  isTextFieldVisible,
  coverLetterBlockData,
  handleInputChange,
  aiCoverLetter,
  handleButtonClick,
  aiTextFieldName,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      {isTextFieldVisible && (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            gap: "48px",
          }}
        >
          <AITextField
            aiTextFieldName={aiTextFieldName}
            text={aiCoverLetter || undefined}
            onChange={handleInputChange}
            handleButtonClick={handleButtonClick}
          />
          <UserTextField
            text={coverLetterBlockData.user_cover_letter}
            onChange={handleInputChange}
          />
        </div>
      )}
    </div>
  );
};

export default CoverLetterTextField;
