import Header from "../components/Header";

import { ResumeList } from "../components/Resume/ResumeList";
import { backGround } from "../colors/BackGround";

function ResumePage() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflowX: "hidden",
        overflowY: "auto",
        backgroundColor: backGround,
      }}
    >
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
          marginTop: "3vh",
          marginBottom: "3vh",
          padding: "0px 10%",
          flexWrap: "wrap",
        }}
      >
        <ResumeList />
      </div>
    </div>
  );
}

export default ResumePage;
