import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { blueGrey } from "@mui/material/colors";
import QuestionComponent from "./QuestionComponent";
import CoverLetterTextField from "./CoverLetterTextField";
import RelatedExperienceTextField from "./RelatedExperience";
import fetchInvoke from "../../../hook/CoverLetter/FetchInvoke";

export function CoverLetterBlockComponent({
  data,
  onUpdate,
  handleTempSave,
  selectResume,
  generationQuota,
  setGenerationQuota,
}) {
  const { id } = useParams();
  const [isEmptyQuestion, setIsEmptyQuestion] = useState(false);
  const [coverLetterBlockData, setCoverLetterBlockData] = useState(data);
  const [aiCoverLetter, setAICoverLetter] = useState(
    coverLetterBlockData.ai_cover_letter
  );
  const [tempSaveTrigger, setTempSaveTrigger] = useState(1);
  const aiTextFieldName = "ai_cover_letter";

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCoverLetterBlockData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    onUpdate({ ...coverLetterBlockData, [name]: value }); // 상위 컴포넌트로 state 전달
  };

  const [isTextFieldVisible, setTextFieldVisible] = useState(true);
  const [
    isRelatedExperienceTextFieldVisible,
    setRelatedExperienceTextFieldVisible,
  ] = useState(true);

  const toggleTextField = () => {
    setTextFieldVisible(!isTextFieldVisible);
  };
  const handleSubtoggleTextField = () => {
    setRelatedExperienceTextFieldVisible(!isRelatedExperienceTextFieldVisible);
  };

  async function handleButtonClick() {
    if (!selectResume) {
      alert("사용할 이력서를 선택하세요.");
      return;
    }
    if (!coverLetterBlockData.question) {
      setIsEmptyQuestion(true);
      alert("자기소개서 질문 항목이 비어있습니다.");
      return;
    }
    if (generationQuota <= 0) {
      alert("생성 가능 횟수가 남아있지 않습니다.");
      return;
    }
    // TODO: 생성 조건 체크
    await handleTempSave();
    setTempSaveTrigger(tempSaveTrigger + 1);
    setGenerationQuota(generationQuota - 1);
    fetchInvoke(
      id,
      aiTextFieldName,
      coverLetterBlockData,
      setAICoverLetter,
      setCoverLetterBlockData,
      onUpdate
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingTop: "24px",
        justifyContent: "center",
        borderBottom: `0.5px solid ${blueGrey[300]}`,
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleSubtoggleTextField}>
          {isRelatedExperienceTextFieldVisible
            ? "관련 경험 제거"
            : "관련 경험 추가"}
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "row",
          }}
        >
          <div>
            <IconButton onClick={toggleTextField}>
              {isTextFieldVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <QuestionComponent
              handleInputChange={handleInputChange}
              coverLetterBlockData={coverLetterBlockData}
              isEmptyQuestion={isEmptyQuestion}
              setIsEmptyQuestion={setIsEmptyQuestion}
              tempSaveTrigger={tempSaveTrigger}
            />

            {isRelatedExperienceTextFieldVisible && (
              <RelatedExperienceTextField
                isTextFieldVisible={isTextFieldVisible}
                handleInputChange={handleInputChange}
                related_experience={coverLetterBlockData.related_experience}
              />
            )}
            <CoverLetterTextField
              isTextFieldVisible={isTextFieldVisible}
              coverLetterBlockData={coverLetterBlockData}
              handleInputChange={handleInputChange}
              aiCoverLetter={aiCoverLetter}
              handleButtonClick={handleButtonClick}
              aiTextFieldName={aiTextFieldName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
