import axiosClient from "../AxiosClient";
import { CoverLetterAPIPrefix } from "./const";

export async function fetchCoverLetterList(setCoverLetter) {
  await axiosClient
    .get(`${CoverLetterAPIPrefix}/list`)
    .then(function (response) {
      setCoverLetter(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}
