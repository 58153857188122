import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { fetchSignOut } from "../../hook/MyPage/FetchSignOut";

export function Settings() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        fontSize: "20px",
        fontWeight: "bold",
        marginTop: "25px",
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}
      >
        회원 탈퇴
      </div>
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          await fetchSignOut();
          navigate("/");
        }}
      >
        계정을 영구적으로 삭제하고 모든 데이터가 제거됩니다.
      </Button>
    </div>
  );
}
