import axiosClient from "../AxiosClient";
import { CoverLetterAPIPrefix } from "./const";

export async function fetchDeleteCoverLetter(coverLetterId) {
  await axiosClient
    .delete(`${CoverLetterAPIPrefix}/${coverLetterId}`)
    .catch(function (error) {
      console.log(error);
    });
}
