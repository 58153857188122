import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function DeleteDialog({
  open,
  type,
  value,
  handleClose,
  onDelete,
  fileId,
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"경고"}</DialogTitle>
      <DialogContent>
        <DialogContentText>삭제하면 되돌릴 수 없습니다.</DialogContentText>
        <DialogContentText>이 {type}를 삭제하시겠습니까?</DialogContentText>
        <br />
        <DialogContentText>{value}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          취소
        </Button>
        <Button
          onClick={() => {
            handleClose();
            onDelete(fileId);
          }}
          autoFocus
        >
          삭제
        </Button>
      </DialogActions>
    </Dialog>
  );
}
