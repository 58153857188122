import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteDialog from "../Dialog/Dialog";

export default function DeleteCoverLetterAlertDlalog({ type, onClick, value }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton
        size="large"
        style={{
          display: "flex",
          width: "40px",
          height: "40px",
          alignItems: "flex-start",
        }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <DeleteOutlineIcon />
      </IconButton>
      <DeleteDialog
        open={open}
        type={type}
        value={value}
        handleClose={handleClose}
        onDelete={onClick}
      />
    </React.Fragment>
  );
}
