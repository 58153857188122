import { EventSourcePolyfill } from "event-source-polyfill";
import axiosClient from "../AxiosClient";

export default function fetchInvoke(
  chatRoomId,
  aiTextFieldName,
  coverLetterBlockData,
  setAICoverLetter,
  setCoverLetterBlockData,
  onUpdate
) {
  var blockId = coverLetterBlockData.id;
  var result = "";

  const eventSourceInitDict = {
    headers: {
      Authorization: axiosClient.defaults.headers.Authorization,
    },
  };
  const uri = `${process.env.REACT_APP_PROXY_URL}/v1/invoke/${chatRoomId}/${blockId}`;
  const eventSource = new EventSourcePolyfill(uri, eventSourceInitDict);

  eventSource.onopen = () => {
    setAICoverLetter("");
  };
  eventSource.onmessage = (event) => {
    var data = event.data;
    result += data;
    setAICoverLetter((prevEvents) => prevEvents + data);
  };
  eventSource.onerror = () => {
    eventSource.close();
    setCoverLetterBlockData((prevState) => ({
      ...prevState,
      [aiTextFieldName]: result,
    }));
    onUpdate({
      ...coverLetterBlockData,
      [aiTextFieldName]: result,
    });
  };
}
