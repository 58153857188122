import TextField from "@mui/material/TextField";

export default function HeaderTitleTextField({ value, onUpdate }) {
  return (
    <TextField
      name="title"
      type="text"
      variant="standard"
      fullWidth={true}
      placeholder="자기소개서 제목을 작성하세요."
      value={value || ""}
      inputProps={{
        style: { fontSize: 36, fontWeight: "bold" },
      }}
      onChange={(event) => {
        onUpdate(event.target.name, event.target.value);
      }}
    />
  );
}
