import axiosClient from "../AxiosClient";
import { CoverLetterBlockAPIPrefix } from "../CoverLetter/const";

export async function fetchDeleteCoverLetterBlock(
  coverLetterId,
  coverLetterBlockId
) {
  await axiosClient
    .delete(
      `${CoverLetterBlockAPIPrefix}/${coverLetterId}/block/${coverLetterBlockId}`
    )
    .catch(function (error) {
      console.log(error);
    });
}
