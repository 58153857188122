import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MyPagePath } from "../../../constant/MenuPath";
import { fetchKakaopayCancelOrFail } from "../../../hook/payment/kakao/FetchKakaoPayCancelOrFail";

function FailPage() {
  const navigate = useNavigate();

  useEffect(() => {
    async function approve() {
      await fetchKakaopayCancelOrFail("FAIL");
      localStorage.removeItem("tid");
      localStorage.removeItem("product_id");
      localStorage.removeItem("total_amount");

      navigate(MyPagePath + "/billing");
    }
    approve();
  }, [navigate]);

  return;
}

export default FailPage;
