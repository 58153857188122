import React, { useState } from "react";
import TextField from "@mui/material/TextField";

function UserTextField({ text, onChange }) {
  let [inputCount, setInputCount] = useState(text ? text.length : 0);

  const onTextareaHandler = (e) => {
    setInputCount(e.target.value.length);
  };

  const textFieldStyles = {
    "& .MuiInputBase-root": { minHeight: 70 },
    marginBottom: "8px",
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        paddingBottom: "24px",
      }}
    >
      {
        <TextField
          type="text"
          name="user_cover_letter"
          onChange={(e) => {
            onChange(e);
            onTextareaHandler(e);
          }}
          value={text || undefined}
          placeholder="제출할 자기소개서를 작성하세요."
          multiline
          sx={textFieldStyles}
        />
      }
      <div style={{ fontSize: 14, textAlign: "end" }}>
        공백 포함 {inputCount} 자
      </div>
    </div>
  );
}
export default UserTextField;
