import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { InfoField } from "../../components/MyPage/InfoField";
import { fetchUserInfo } from "../../hook/MyPage/FetchUserInfo";
import { Settings } from "../../components/MyPage/Settings";

function ProfilePage() {
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    fetchUserInfo(setUserInfo);
  }, []);

  return (
    <div style={{ minWidth: "30vw", display: "flex", flexDirection: "column" }}>
      <InfoField title={"이름"} value={userInfo.fullName} disabled={true} />
      <InfoField title={"이메일"} value={userInfo.email} disabled={true} />
      <InfoField
        title={"휴대전화"}
        value={userInfo.phoneNumber}
        disabled={true}
      />
      <Divider />
      <Settings />
    </div>
  );
}

export default ProfilePage;
