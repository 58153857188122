import axiosClient from "../AxiosClient";
import { CoverLetterAPIPrefix } from "./const";

export async function fetchSaveQuestion(coverLetterBlockId, question) {
  var result = {};

  const body = { block_id: coverLetterBlockId, question: question || "" };
  await axiosClient
    .patch(`${CoverLetterAPIPrefix}/question/save`, body)
    .then(function (response) {
      result = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });

  return result;
}
