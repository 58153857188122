import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { ResumePath } from "../constant/MenuPath";
import { useKeycloak } from "@react-keycloak/web";
import { TermsPath, PrivacyPath } from "../constant/terms/Path";

function HomePage() {
  const navigate = useNavigate();
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (keycloak?.authenticated) {
      navigate(ResumePath);
    }
  }, [keycloak, initialized, navigate]);

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo">CV Guide</div>
      </header>
      <div className="Body-main">
        <section id="hero">
          <h1>막막한 자기소개서</h1>
          <h1>AI와 함께 준비하세요!</h1>
        </section>
        <section id="about">
          <p>CV Guide는 지원자의 이력서를 바탕으로 자기소개서 질문을 분석</p>
          <p>최적의 답변을 준비할 수 있도록 도와줍니다.</p>
          <ul>
            <li>이력서 분석</li>
            <li>질문 분석</li>
            <li>답변 가이드 제공</li>
          </ul>
          <button onClick={() => keycloak.login()}>지금 시작하기</button>
        </section>
      </div>
      <footer>
        <ul>
          <li>
            <a href={TermsPath}>이용 약관</a>
          </li>
          <li>
            <a href={PrivacyPath}>개인정보 처리방침</a>
          </li>
          {/* <li>
            <a href="#contact">문의하기</a>
          </li> */}
        </ul>
        <div className="social-icons">{/* 소셜 미디어 아이콘 */}</div>
        <p>&copy; 2024. 아쿠아플로우AI. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default HomePage;
