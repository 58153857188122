import axiosClient from "../AxiosClient";
import { CoverLetterAPIPrefix } from "./const";

export async function fetchSaveAll(coverLetterId, header, status, block_list) {
  var result = {};

  header["status"] = status;
  const body = {
    cover_letter_id: coverLetterId,
    header: header,
    block_list: block_list,
  };
  await axiosClient
    .put(`${CoverLetterAPIPrefix}/save`, body)
    .catch(function (error) {
      console.log(error);
    });

  return result;
}
