import Button from "@mui/material/Button";
import { blueGrey } from "@mui/material/colors";
import { fetchResumeDownload } from "../../hook/Resume/FetchResumeDownload";
import { fetchResumeList } from "../../hook/Resume/FetchResumeList";
import { fetchResumeRemove } from "../../hook/Resume/FetchResumeRemove";
import DeleteResumeAlertDlalog from "./DeleteResumeAlertDlalog";

export function ResumeBtn({ fileId, filename, created, setResumeList }) {
  const handleFileDownloadBtnClick = () => {
    fetchResumeDownload(fileId, filename);
  };

  const handleRemoveResumeBtnClick = async (fileId) => {
    await fetchResumeRemove(fileId);
    await fetchResumeList(setResumeList);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "row",
        border: `1px solid ${blueGrey[300]}`,
        borderRadius: "10px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "100px",
          display: "flex",
          flexDirection: "row",
          flex: "1",
          color: "black",
          justifyContent: "space-between",
          paddingLeft: "24px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
            justifyContent: "center",
          }}
        >
          <Button
            variant="text"
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              color: "black",
              padding: 0,
              textTransform: "none",
            }}
            onClick={handleFileDownloadBtnClick}
          >
            {filename}
          </Button>
          <div style={{ fontSize: "14px" }}>{created}</div>
        </div>
      </div>
      <DeleteResumeAlertDlalog
        type={"이력서"}
        onClick={handleRemoveResumeBtnClick}
        value={filename}
        fileId={fileId}
      />
    </div>
  );
}
