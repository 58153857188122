import axiosClient from "../AxiosClient";

export async function fetchResumeDownload(fileId, filename) {
  const response = await axiosClient
    .get(`/file/download/${fileId}`)
    .catch(function (error) {
      console.log(error);
    });

  // 파일 다운로드를 위해 a 태그를 동적으로 생성합니다.
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  // 다운로드 후 URL 객체 제거
  window.URL.revokeObjectURL(url);
}
