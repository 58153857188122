import React, { Fragment } from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Alert from "@mui/material/Alert";
import { fetchCreateCoverLetter } from "../../hook/CoverLetter/FetchCreateCoverLetter";

function NewCoverLetterCard({ onClick, nowCount, maxCount }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClickVariant = () => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(`자기소개서는 최대 ${maxCount}개까지 생성할 수 있습니다!`, {
      autoHideDuration: 5000,
      content: (key, message) => (
        <Alert
          onClose={() => closeSnackbar(key)}
          severity="error"
          variant="filled"
          sx={{ width: "100%", backgroundColor: "#1976d2" }}
        >
          {message}
        </Alert>
      ),
    });
  };
  return (
    <Fragment>
      <Card>
        <Button
          style={{
            width: "18vw",
            height: "25vh",
            display: "flex",
            flexDirection: "column",
          }}
          onClick={async () => {
            var response = await fetchCreateCoverLetter();
            if (response === true) onClick();
            else handleClickVariant();
          }}
        >
          <div>
            <NoteAddIcon sx={{ fontSize: 80, color: "#1976d2" }} />
          </div>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            새 자기소개서 작성 {nowCount} / {maxCount}
          </Typography>
        </Button>
      </Card>
    </Fragment>
  );
}
export default function NewCoverLetterIntegrationNotistack({
  onClick,
  nowCount,
  maxCount,
}) {
  return (
    <SnackbarProvider maxSnack={3}>
      <NewCoverLetterCard
        onClick={onClick}
        nowCount={nowCount}
        maxCount={maxCount}
      />
    </SnackbarProvider>
  );
}
