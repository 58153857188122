import Keycloak from "keycloak-js";

const config = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
};
const keycloak = new Keycloak(config);

export default keycloak;
