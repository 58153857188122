import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { backGround } from "../../colors/BackGround";
import ProductMenu from "../../components/Price/ProductMenu";
import { fetchProductList } from "../../hook/Price/FetchProductList"

function PriceMainPage() {
  const [productMenuList, setProductMenuList] = useState([]);

  useEffect(() => {
    fetchProductList(setProductMenuList);
  }, []);

  return (
    <div style={styles.page}>
      <Header />
      <div style={styles.bodyBackground}>
        {productMenuList.map((value, index) => {
          return (
            <ProductMenu
              key={index}
              productId={value.id}
              productName={value.name}
              price={value.price}
            />
          );
        })}
      </div>
    </div>
  );
}

const styles = {
  page: {
    height: "100vh",
    width: "100vw",
    overflowX: "hidden",
    overflowY: "hidden",
    backgroundColor: backGround,
  },
  bodyBackground: {
    display: "flex",
    width: "100vw",
    height: "calc(100vh - 8vh - 1px)",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    gap: "24px",
  },
};

export default PriceMainPage;
