import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CoverLetterStatus from "../../enums/CoverLetterStatus";
import { CoverLetterAPIPrefix } from "../../hook/CoverLetter/const";
import DeleteCoverLetterAlertDlalog from "./DeleteCoverLetterAlertDlalog";

const styles = {
  card: { display: "flex", flexDirection: "row" },
  btn: {
    width: "calc(18vw - 40px)",
    height: "25vh",
    alignItems: "flex-start",
    padding: "14px",
  },
  btnInner: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyItems: "start",
  },
};

export default function CoverLetterCard({ cardData, handleDeleteBtnOnClick }) {
  const navigate = useNavigate();

  function handleOnClick(coverLetterId) {
    navigate(`${CoverLetterAPIPrefix}/${coverLetterId}`);
  }

  return (
    <Card style={styles.card}>
      <Button
        onClick={() => {
          handleOnClick(cardData.id);
        }}
        style={styles.btn}
      >
        <div style={styles.btnInner}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {cardData.created_at}
          </Typography>
          <div
            style={{
              width: "100%",
              fontSize: "24px",
              textAlign: "start",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {cardData.title}
          </div>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {CoverLetterStatus[cardData.status]}
          </Typography>
        </div>
      </Button>
      <DeleteCoverLetterAlertDlalog
        type={"자기소개서"}
        onClick={() => {
          handleDeleteBtnOnClick(cardData.id);
        }}
        value={cardData.title}
      />
    </Card>
  );
}
